import Rails from '@rails/ujs'

const isSkipParentalGateRequired = () => {
  return $('.js-skip-parental-gate-check').length > 0 || !location.pathname.startsWith('/premium')
}

export const checkParentalGateRequired = () => {
  if (isSkipParentalGateRequired()) {
    return
  }

  Rails.ajax({
    type: 'GET',
    url: '/premium/parental_gate.json',
    success(_resp, _status, xhr) {
      const resp = JSON.parse(xhr.responseText)
      if (resp['required']) {
        location.href = resp['redirectTo']
      }
    },
  })
}
